import axios from "axios"
import { Message } from 'element-ui';

axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
//设置超时

//token拦截器
axios.interceptors.request.use(config => {
    const token = sessionStorage.getItem("token");
    if (token) {
        config.Authorization = token;
    }
    return config;
})

axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if(error.message === 'Request failed with status code 500'){
            console.info(error.message)
            console.info("path:"+ document.location.pathname)
            if(document.location.pathname !== '/'){
                window.location.href="/"
            }
        }else{
            window.location.href="https://inccrypto.com/en-error"
            // Message({
            //     message: error.message,
            //     type: 'error'
            // })
        }
    }
);
const Axios = {
    post(url, data, params, headers) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data: data,
                params: params,
                headers
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    get(url, params) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                params: params,
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    put(url, data, params, headers) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'put',
                url,
                data: data,
                params: params,
                headers
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    delete(url, params) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                url,
                params: params,
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

export default Axios;