<template>
  <div class="footer-bar-wrap">
    <div id="footer-bar" class="footer-bar-5">
      <a id="fhome" href="/" style="" :class="{active: path == '/'}">
        <!--        <i class="fa fa-home"></i><span>Home</span>-->
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
        <span>Home</span>
      </a>
<!--      <a id="fplans" href="/plans" class="footer_iconfire" :class="{active: path == '/plans'}">-->
<!--        &lt;!&ndash;      <img style="position: absolute;top:0.3rem;right:0px;width:22px;" src="/assets/img/icon/hot2.png">&ndash;&gt;-->
<!--&lt;!&ndash;        <i class="fa fa-cart-plus"></i>&ndash;&gt;-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>-->
<!--        <span>Plans</span>-->
<!--      </a>-->
      <router-link v-if="!login" id="fdashboard" to="/auth-login">
<!--        <i class="fa fa-dashboard"></i>-->
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-command"><path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg>
        <span>Dashboard</span>
      </router-link>

      <router-link v-else id="fdashboard" to="/profile/overview" :class="{active: path == '/profile/overview'}">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-command"><path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg>
        <span>Dashboard</span>
      </router-link>
      <a v-if="!login" id="freferral" href="/referral-program" :class="{active: path == '/referral-program'}">
<!--        <i class="fa fa-users"></i>-->
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
        <span>Referral</span>
      </a>
      <a v-if="!login" id="ffaqs" href="/questions" :class="{active: path == '/questions'}">
<!--        <i class="fa fa-question-circle"></i>-->
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
        <span>FAQ</span>
      </a>
      <router-link  v-if="login"  id="fdeposit" to="/profile/deposits" :class="{active: path == '/profile/deposits'}">
<!--        <i class="fa fa-gift"></i>-->
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
        <span id="fdeposit2" class="foot-bar-span">Deposits</span>
      </router-link>
<!--      <router-link v-if="login" to="/profile/withdraws"></router-link>-->
      <router-link v-if="login" id="fwithdraws" to="/profile/withdraws" :class="{active: path == '/profile/withdraws'}">
<!--        <i class="fa fa-credit-card"></i>-->
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
        <span id="fwithdraws2" class="foot-bar-span">Withdraws</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import storage from "@/storage";
import indexApi from "@/apis/indexApi";

export default {
  name: "FooterBar",
  data() {
    return {
      path: '',
      login: false
    }
  },
  watch: {
    $route(to, from){
      console.log('route change')
      console.log('current: ' , to);
      console.log('previous: ' , from);
      this.path = to.path
      let userId = storage.localGet("userId")
      if (userId) {
        this.login = true
      }
    },
  },
  mounted() {
    // 隐藏页面内容
    // this.$el.style.display = 'none'
    // indexApi.CheckProxy("322b7u-98q484-557691-284823",res=> {
    //   console.log('proxy:'+res.data)
    //   if (res.data === true) {
    //     // 跳转到新页面
    //     if(navigator.language === 'zh-CN'){
    //       window.location.href = "https://inccrypto.com/cn-error"
    //     }else{
    //       window.location.href = "https://inccrypto.com/en-error"
    //     }
    //   } else {
    //     // 显示页面内容
    //     this.$el.style.display = ''
    //   }
    // })

    console.log(this.$route.path)
    this.path = this.$route.path;
    //
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }
  }
}
</script>

<style scoped lang="scss">

#footer-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
  background-color: rgba(249, 249, 249, .98);
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, .06);
  height: 60px;

  text-align: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: all 300ms ease;
  display: none;
}

#footer-bar a {
  color: #1f1f1f;
  padding-top: 12px;
  position: relative;
  flex: 1 1 auto
}

#footer-bar a.active {
  color: rgb(0, 0, 255);
}

#footer-bar a span {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin-top: -6px;
  opacity: .7;
  font-family: roboto, sans-serif !important
}

#footer-bar a svg {
  margin-top: -8px
}

#footer-bar a i {
  margin-top: -3px;
  font-size: 18px;
  position: relative;
  z-index: 2
}

#footer-bar .badge {
  font-style: normal;
  z-index: 5;
  top: 0;
  position: absolute;
  margin-left: 3px;
  color: #fff !important;
  width: 18px;
  text-align: center;
  line-height: 18px;
  padding: 0;
  padding-left: 0 !important;
  border-radius: 18px;
  margin-top: 7px;
  font-size: 11px
}

.footer-bar-2 .active-nav {
  color: #fff !important
}

.footer-bar-2 .active-nav strong {
  position: absolute;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0
}

.footer-bar-4 .active-nav {
  color: #fff !important
}

.footer-bar-4 .active-nav strong {
  position: absolute;
  width: 47px;
  height: 47px;
  border-radius: 60px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: 0
}

.footer-bar-4 span {
  display: none !important
}

.footer-bar-4 i {
  padding-top: 10px
}

.footer-bar-5 .active-nav strong {
  position: absolute;
  width: 50px;
  height: 4px;
  border-radius: 60px;
  left: 50%;
  bottom: 0;
  bottom: calc(0px + (constant(safe-area-inset-bottom)) * 1.1);
  bottom: calc(0px + (env(safe-area-inset-bottom)) * 1.1);
  transform: translateX(-50%)
}

.footer-bar-3 span {
  display: none !important
}

.footer-bar-3 .active-nav {
  padding-top: 11px !important
}

.footer-bar-3 .active-nav span {
  display: block !important
}

.footer-bar-3 a {
  padding-top: 18px !important
}

.footer-menu-hidden {
  transition: all 100ms ease;
  transform: translateY(100%) !important
}

.footer-bar-white * {
  color: #fff
}

#footer-bar.position-relative {
  z-index: 2 !important
}

#footer-bar {
  height: calc(60px + (constant(safe-area-inset-bottom)) * 1.1);
  height: calc(60px + (env(safe-area-inset-bottom)) * 1.1)
}

.footer-bar-5.position-relative .active-nav strong {
  bottom: 0 !important
}

@media only screen and (max-width: 992px) {
  .footer-bar-wrap {
    padding: 30px;
  }
  #footer-bar {
    display: flex;
  }
}
</style>
