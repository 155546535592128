<template>
<div id="app">
<!--  <Navbar4></Navbar4>-->
    <router-view />
  <footer-bar></footer-bar>
</div>
</template>

<style lang="scss">
.container{
  @media (min-width: 1200px) {
    max-width: 1240px;
  }
}
#topnav{
 @media (min-width: 1200px){
  height: 74px;
 }
}
#topnav .buy-button {
	@media (max-width: 768px){
	 line-height: 85px;
	 height: 50px;
	}
}
</style>
<script>
// import FooterBar from "@/components/footerBar";
import FooterBar from "@/views/landing/footerBarMobile";
import indexApi from "@/apis/indexApi";
export default {
  components:{FooterBar},
  created() {
    this.fetchRouteParams();
    this.fetchReferrer();
  },
  data() {
    return {
      param: undefined
    }
  },
  methods:{
    fetchRouteParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const s = urlParams.get('s');
      const legacyS = this.getLegacyQueryParam('s');
      const routeS = s || legacyS;
      // console.log('s:', routeS);
      this.param =  routeS
    },
    getLegacyQueryParam(paramName) {
      const query = window.location.search.substr(1);
      const pairs = query.split('&');
      for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        if (decodeURIComponent(pair[0]) === paramName) {
          return decodeURIComponent(pair[1]);
        }
      }
      return null;
    },
    fetchReferrer(){
      // let ref = document.referrer || '';
      // try {
      //   if (ref.length === 0 && opener.location.href.length > 0) {
      //     ref = opener.location.href;
      //   }
      // } catch (e) {
      //   console.log(e.message);
      // }

      let r = document.referrer.toLowerCase().replace('https://','');
      if(r === '' || r === null) {
        r = window.location.hostname
      }

      const urlParams = new URLSearchParams(window.location.search);
      const utmCampaign = urlParams.get('utm_campaign');
      const utmSource = urlParams.get('utm_source');

      if (utmCampaign === 'coinband') {
        if (utmSource === 'google') {
          sessionStorage.setItem('source', 'cb-google');
        } else if (utmSource === 'facebook') {
          sessionStorage.setItem('source', 'cb-facebook');
        }
      } else if (utmSource === 'taboola' || utmSource === '+Coingape' || utmSource === 'coingape') {
        sessionStorage.setItem('source', utmSource);
      } else if(utmSource === 'dot'){
        r = 'dot'
      } else if (utmSource === 'coingape') {
        r = 'coingape'
      }


      let para = this.param;
      let set = new Set([r]);
      if (typeof para === 'string' && para.length > 0) {
        if (para === 'coinband-google') {
          para = 'cb-google';
        }
        set = new Set([para]);
        if(para === 'chainwire-org' ) {
          set = new Set(['chainwire-org',r]);
        }
      }
      let source = Array.from(set);


      source = source.map(item => {
        if (item.includes('https://inccrypto.com/?s=')) {
          return item.replace('https://inccrypto.com/?s=', '');
        } else if (item.includes('https://inccrypto.uk/?s=')) {
          return item.replace('https://inccrypto.uk/?s=', '');
        } else if (item === 'https://www.bahianoticias.com.br') {
          return 'Bahianoticias-com-br';
        } else if (item === 'https://ua.korrespondent.net' || item === 'https://korrespondent.net') {
          return 'Korrespondent-net-2';
        } else if (item.slice(-1) === '/') {
          return item.slice(0, -1);
        } else if (item.includes('/?s=')){
          return item.split('/?s=')[1];
        } else if (item.includes('https://')){
          return item.replace('https://','')
        }
        return item;
      });

      if (!sessionStorage.getItem('source')) {
        sessionStorage.setItem('source', source.join(' / '));
      }
    },

  }
};
</script>



