import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'


// console.log("navigator.language:"+navigator.language)
// if(navigator.language === 'zh-CN'){
//   console.log("yoxixixixixi")
// }else{
//   console.log("kkkkkkkkkkkkkkkkkk")
// }
// indexApi.CheckProxy("322b7u-98q484-557691-284823",res=> {
//   console.log('proxy:'+res.data)
//   if (res.data === true) {
//     // 跳转到新页面
//     if(navigator.language === 'zh-CN'){
//       window.location.href = "https://inccrypto.com/cn-error"
//     }else{
//       window.location.href = "https://inccrypto.com/en-error"
//     }
//   }
// })



Vue.config.productionTip = false

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});


import "../public/css/materialdesignicons.min.css";
import 'swiper/swiper-bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { BootstrapVue } from 'bootstrap-vue'
var VueScrollTo = require('vue-scrollto');
import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueMasonry from 'vue-masonry-css'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import VueClipboard from 'vue-clipboard2'
import 'element-ui/lib/theme-chalk/index.css';
import indexApi from "@/apis/indexApi";
Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease"
})

Vue.use(BootstrapVue)
Vue.use(Scrollspy);
Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)
Vue.use(VueMasonry)
Vue.use(ElementUI, { locale })
Vue.use(VueClipboard)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
